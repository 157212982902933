<template>
  <v-container class="pa-0 ma-0" fluid>
    <div class="d-flex" v-if="client && !loading">
      <div style="width: 50%" tile>
        <v-card-title>Client:</v-card-title>
        <v-list-item>
          <v-list-item-content>
            <v-container fluid>
              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("id") }}:</b>
                </v-col>
                <v-col>{{ client.client_id }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("name") }}:</b>
                </v-col>
                <v-col>{{ client.client_name }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("code") }}:</b>
                </v-col>
                <v-col>{{ client.appCode }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("type") }}:</b>
                </v-col>
                <v-col>{{ client.client_type }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("_uri") }}:</b>
                </v-col>
                <v-col>{{ client._uri }}</v-col>
              </v-row>

              <!-- <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("identityId") }}:</b>
                </v-col>
                <v-col>{{ client.identityId }}</v-col>
              </v-row> -->

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("identity") }}:</b>
                </v-col>
                <v-col>
                  <div class="d-flex flex-column">
                    {{ client.identityId.name | empty }}
                    <span class="caption">
                      {{ client.identityId._uri | empty }}
                    </span>
                  </div>
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("ownedBy") }}:</b>
                </v-col>
                <v-col>{{ client.ownedBy }}</v-col>
              </v-row> -->

              <v-row v-if="client.ownedBy">
                <v-col class="col-sm-3">
                  <b>{{ $t("ownedBy") }}:</b>
                </v-col>
                <v-col>
                  <div class="d-flex flex-column">
                    {{ client.ownedBy.name | empty }}
                    <span class="caption">
                      {{ client.ownedBy._uri | empty }}
                    </span>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("createdAt") }}:</b>
                </v-col>
                <v-col>{{ client.createdAt | getDisplayDate }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("createdBy") }}:</b>
                </v-col>
                <v-col>{{ client.createdBy.name | empty }}</v-col>
              </v-row>

              <v-row v-if="client.updatedAt">
                <v-col class="col-sm-3">
                  <b>{{ $t("updatedAt") }}:</b>
                </v-col>
                <v-col>{{ client.updatedAt | getDisplayDate }}</v-col>
              </v-row>

              <v-row v-if="client.updatedAt">
                <v-col class="col-sm-3">
                  <b>{{ $t("updatedBy") }}:</b>
                </v-col>
                <v-col>{{ client.updatedBy.name | empty }}</v-col>
              </v-row>
            </v-container>
          </v-list-item-content>
        </v-list-item>
      </div>
      <div style="width: 50%" tile>
        <v-card-title>Scopes:</v-card-title>
        <v-list-item v-for="item in scopes" :key="item.scopeId">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.scopeName }}
            </v-list-item-title>
            <v-list-item-subtitle class="text-wrap">
              <span>{{ item._uri }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              >{{ $t("createdAt") }}:
              {{ item.createdAt | getDisplayDate }}
              ({{ item.createdBy.name | empty }})
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.updatedAt">
              {{ $t("updatedAt") }}:
              {{ item.updatedAt | getDisplayDate }}
              ({{ item.updatedBy.name | empty }})
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-card-title>Attributes:</v-card-title>
        <GsAttributesDisplay
          v-if="client"
          :entityId="client.client_id"
          entityName="clients"
          collection="client"
        />
        <v-card-title>Tags:</v-card-title>
        <GsTagsDisplay
          v-if="client"
          :id="client.client_id"
          endpoint="/clients"
          collection="client"
          tagType="test"
        />
      </div>
    </div>
    <div v-if="!client && !loading">
      <span>{{ $t("no_data") }}</span>
    </div>
    <div class="text-center" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
        class="ma-4"
      ></v-progress-circular>
      <span>{{ $t("loading") }}</span>
    </div>
  </v-container>
</template>

<script>
import GsAttributesDisplay from "@/components/AttributeEditor/GsAttributesDisplay";
import GsTagsDisplay from "@/components/AttributeEditor/GsTagsDisplay";

export default {
  name: "GsClientDetails",
  components: {
    GsAttributesDisplay,
    GsTagsDisplay
  },
  props: {
    client: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      scopes: [],
      loading: true
    };
  },
  created: async function() {
    this.getScopes(this.client);
  },
  watch: {
    client(value) {
      this.getScopes(value);
    }
  },
  methods: {
    async getScopes(client) {
      this.items = [];
      this.loading = true;
      if (!client?.scope?.length) {
        this.loading = false;
        return;
      }
      const items = await this.getScope(
        client.scope,
        "&r8sFields=createdBy.name,updatedBy.name"
      );
      this.scopes = items;
      this.loading = false;
    }
  }
};
</script>
