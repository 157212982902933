<template>
  <v-container class="container" fluid>
    <v-card flat class="mb-3">
      <v-btn
        small
        class="elevation-4"
        color="primary"
        v-on:click="add_click()"
        >{{ $t("add_button") }}</v-btn
      >
      <!-- <GsClientEditor
        ref="editor"
        :cancel="cancel_click"
        :save="save_click"
        v-if="status == statusValues.add || status == statusValues.edit"
      /> -->
    </v-card>
    <!-- filter panel -->
    <GsDataFilter
      ref="dataFilter"
      :appSetting="appSettingKey"
      :filters="filters"
      v-on:changed="onFilterChanged"
    />
    <!-- table -->
    <GsDataTable
      ref="table"
      api="idp_api_url"
      endpoint="/clients"
      :appSetting="appSettingKey"
      :headers="headers"
      :default-sort-by="['client_name']"
      :default-sort-desc="[false]"
      :beforeCallApi="beforeCallApi"
      :afterCallApi="afterCallApi"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="client_id"
      show-expand
      @click:row="rowClicked"
      @after-refresh="afterRefresh"
    >
      <template v-slot:item.createdAt="{ item }">
        <span
          >{{ item.createdAt | getDisplayDate }} ({{
            item.createdBy.name | empty
          }})</span
        >
      </template>
      <template v-slot:item.updatedAt="{ item }">
        <span v-if="item.updatedAt"
          >{{ item.updatedAt | getDisplayDate }} ({{
            item.updatedBy.name | empty
          }})</span
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <GsActionsMenu
          :actions="actions"
          :onActivate="actionId => action_activate(actionId, item)"
        />
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <GsClientDetails :client="item" />
        </td>
      </template>
    </GsDataTable>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
// import GsClientEditor from "@/components/GsClientEditor";
import GsClientDetails from "@/components/GsClientDetails";
import { GsDataTable, GsActionsMenu, GsDataFilter } from "ngt-frontend-core";

export default {
  name: "Clients",
  components: {
    // GsClientEditor,
    GsClientDetails,
    GsDataTable,
    GsActionsMenu,
    GsDataFilter
  },
  props: {},
  data() {
    return {
      appSettingKey: "clients",
      items: [],
      expanded: [],
      singleExpand: true,
      initialized: false,
      actions: ["attributes", "tags", "edit"],
      // status: 0,
      // statusValues: {
      //   browse: 0,
      //   add: 1,
      //   edit: 2
      // },
      filters: [
        {
          field: "client_name",
          component: "GsTextFilter",
          params: {}
        },
        {
          field: "client_type",
          component: "GsSelectFilter",
          params: {
            options: this.$store.state.lookups.client_types
          }
        },
        {
          field: "client_id",
          component: "GsIdFilter",
          params: {}
        }
      ]
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("actions"),
          value: "actions",
          width: "10%",
          sortable: false
        },
        {
          text: this.$t("client_name"),
          align: "left",
          value: "client_name",
          width: "15%",
          sortable: true
        },
        {
          text: this.$t("client_type"),
          align: "left",
          value: "client_type",
          width: "15%",
          sortable: true
        },
        {
          text: this.$t("identity"),
          align: "left",
          value: "identityId.name",
          width: "8%",
          sortable: false
        },
        {
          text: this.$t("redirect_uri"),
          align: "left",
          value: "redirect_uri",
          width: "8%",
          sortable: false
        },
        {
          text: this.$t("_uri"),
          align: "left",
          value: "_uri",
          width: "8%",
          sortable: false
        },
        {
          text: this.$t("createdAt"),
          value: "createdAt",
          width: "15%",
          sortable: true
        },
        {
          text: this.$t("updatedAt"),
          value: "updatedAt",
          width: "15%",
          sortable: true
        },
        { text: "", value: "data-table-expand", width: "5%", sortable: false }
      ];
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.initialized = false;
    this.$nextTick(() => {
      this.initialized = true;
    });
  },
  methods: {
    async refreshTable() {
      try {
        // console.log("refreshTable");
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      params.url += this.$refs.dataFilter.getFilter();
      params.url +=
        "&r8sFields=createdBy.name,updatedBy.name,ownedBy.name,identityId.name";
      return params;
    },
    async afterCallApi(params) {
      return params;
    },
    afterRefresh({ items }) {
      const expandedId = this.loadExpanded();
      if (expandedId) {
        const expandItem = items.find(p => p.client_id == expandedId);
        if (expandItem) {
          this.expand(expandItem);
        }
      }
    },
    onFilterChanged() {
      this.$refs.table.goToFirstPage();
    },
    async getClient(client_id) {
      const result = await callAPI({
        url: `${this.$store.state.idp_api_url}/clients/${client_id}`,
        method: "GET"
      });
      return result.data;
    },
    async add_click() {
      // this.status = this.statusValues.add;
      // this.expanded = [];
      // this.$nextTick(function () {
      //   // const initData = {
      //   //   client_name: "test4",
      //   //   client_type: "public",
      //   //   redirect_uri: "https://ngt-id4all-res-test3.gsgroup.io",
      //   //   logoUri: "https://example.com/logo.png",
      //   //   scope: [],
      //   //   identityId: "https://ngt-id4all-res.gsgroup.io/identities/01d1eebc-a2ec-40b9-98c9-5fc2bf723b00"
      //   // };
      //   const initData = {
      //     client_name: null,
      //     client_type: null,
      //     redirect_uri: null,
      //     logoUri: null,
      //     scope: [],
      //     identityId: null
      //   };
      //   this.$refs.editor.add(initData);
      // });
      this.$router.push_safe(`/clients/new`);
    },
    async edit_click(item) {
      // this.status = this.statusValues.edit;
      // this.expanded = [];
      // this.$nextTick(async function () {
      //   let initData = await this.getClient(item.client_id);
      //   if (typeof initData.scope === "undefined") initData.scope = [];
      //   this.$refs.editor.edit(initData);
      // });
      this.expand(item);
      this.$router.push_safe(`/clients/${item.client_id}`);
    },
    async attributes_click(item) {
      this.expand(item);
      this.$router.push_safe(`/clients/${item.client_id}/attrs`);
    },
    async tags_click(item) {
      this.expand(item);
      this.$router.push_safe(`/clients/${item.client_id}/tags`);
    },
    // async cancel_click() {
    //   this.status = this.statusValues.browse;
    // },
    // async save_click(sendData) {
    //   // console.log("sendData", sendData)
    //   let url = `${this.$store.state.idp_api_url}/clients`;
    //   let method = "POST";
    //   if (sendData.client_id) {
    //     url += `/${sendData.client_id}`;
    //     method = "PATCH";
    //   }
    //   delete sendData.client_id;
    //   delete sendData.createdBy;
    //   delete sendData.createdAt;
    //   delete sendData.updatedBy;
    //   delete sendData.updatedAt;
    //   delete sendData._uri;

    //   if (sendData.redirect_uri == null || sendData.redirect_uri == "") {
    //     delete sendData.redirect_uri;
    //   }

    //   if (sendData.logoUri == null || sendData.logoUri == "") {
    //     delete sendData.logoUri;
    //   }

    //   const data = sendData;
    //   try {
    //     await callAPI({ url, method, data });
    //   } catch (err) {
    //     console.log(err);
    //     this.validationResult = err;
    //   }
    //   this.status = this.statusValues.browse;
    //   await this.refreshTable();
    // },
    async delete_click(item) {
      alert("Not implemented!");
    },
    async action_activate(actionId, item) {
      switch (actionId) {
        case "attributes":
          this.attributes_click(item);
          break;
        case "tags":
          this.tags_click(item);
          break;
        case "edit":
          this.edit_click(item);
      }
    },
    rowClicked(item) {
      const earlyReturn = this.expanded.some(
        p => p.client_id === item.client_id
      );
      if (earlyReturn) {
        this.expanded = [];
        this.saveExpanded(null);
        return;
      }
      this.expand(item);
    },
    expand(item) {
      if (!this.$refs.table) return;
      if (item) {
        this.expanded = [item];
        this.saveExpanded(item.client_id);
        this.identity = item;
      }
    }
  }
};
</script>

<style lang="scss"></style>
